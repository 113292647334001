* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root { 
  --bgcolor: hsl(213, 60%, 3%);
  --secondary: #ce93d8
}

html {
  background-color:  hsl(212, 61%, 3%);
}

.App {
  background-color:  hsl(212, 61%, 3%);
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column; 
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  max-width: 1100px;
  margin: 64px auto;
}

a {
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 4px #132F4C;
}

form > :nth-child(n + 2),
.div-inputs > :nth-child(n + 2) {
  margin-top: 16px;
}

.to-top {
  top: 65px;
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  max-width: 1100px;
}

.wide-box {
  width: 90%;
}

.App-loading {
  width: 60vw;
  pointer-events: none;
}

.App-link {
  color: #fff;
}

.text-center {
  text-align: center
};


.w-100 {
  width: 100%;
  max-width: 100vw;
}

.h-100 {
  height: 100%;
  max-height: 100vw;
}

.w-80 {
  width: 80%;
  max-width: 80vw;
}

.h-100 {
  height: 100vh;
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: max(50%, 400px);
  margin-bottom: 48px;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.flex-col {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
  justify-self: center;
}

.translate-down-5px {
  transform: translate(0, 5px);
}

.MuiCalendarPicker-root {
  background: hsl(212, 61%, 10%);
}

.MuiDialogContent-root {
  background-image: none;
  background: hsl(212, 61%, 10%);
}

.MuiDialogActions-root {
  background-image: none;
  background: hsl(212, 61%, 19%);
}

.PrivatePickersToolbar-root {
  background: hsl(212, 61%, 19%);
}

div[role="dialog"] > .MuiPaper-root > div {
  background: hsl(212, 61%, 10%);
  background-image: none;
  border: 1px solid #0288d1;
  border-radius: 10px;
}

.MuiTabs-root {
  background: hsl(212, 61%, 19%);
}

.MuiAutocomplete-popper > * > * > * {
  border-bottom: 1px solid black;
}

.MuiAutocomplete-popper > * > * > * {
  background: hsl(212, 61%, 10%);
  background-image: none;
}

.MuiChip-filled {
  font-size: 16px;
  height: 26px;
}

.search-users {
  position: sticky;
  width: 100%;
  background:  hsl(212, 61%, 3%);
  height: 60px;
  top: 64px;
  padding-top: 12px;
  z-index: 1002;
}

.h-100 {
  height: 100%;
}

.sticky-subtitle {
  margin: 0;
  position: sticky;
  top: 124px;
}

.people-subtitle {
  padding-top: 12px;
  border-bottom: 1px solid grey;
  background: hsl(212, 61%, 3%);
}

.spinning {
  animation: spin 500ms linear none infinite;
}

.add-option-button::before {
  content: '';
  width: 1px;
  height: 38px;
  position: absolute;
  top: -40px;
  border-left: 1px dashed rgba(206, 147, 216, 0.5) ;
}

.swipeable-list {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.swipeable-list-item {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  z-index: 0;
  cursor: pointer;
}

.swipeable-list-item__content {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  background: hsl(212, 61%, 3%);
}

.swipeable-list-item__content--return {
  transition: transform 0.5s ease-out;
}

.swipeable-list-item__content--remove {
  transition: transform 0.35s ease-in;
}

.swipeable-list-item__content-right {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
}

.swipeable-list-item__content-right--return {
  transition: opacity 0.5s ease-out;
}

.swipeable-list-item__content-left {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
  justify-content: flex-end;
}

.swipeable-list-item__content-left--return {
  transition: opacity 0.5s ease-out;
}

.main-grid {
  width: 85%;
  max-width: 1100px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  padding: 48px 0;
  position: absolute;
  top: max(50%, 430px);
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.main-grid > a {
  display: flex;
  justify-content: center;
}

.main-grid > a > button  {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5%;
  border: 1px solid #132F4C;
  background-color: hsl(212, 61%, 5%);
  color: white;
  box-shadow: 1px 1px 3px 0 hsl(211, 60%, 50%);
  text-decoration: none;
}

@media screen and (max-width: 520px) {
  .wide-box {
    width: 100%;
  }

  .to-top {
    width: 95%;
  }
}

@media screen and (max-width: 700px) {
  .main-grid {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .to-top {
    width: 95%;
  }
}

@media screen and (max-width: 300px) {
  .main-grid {
    gap: none; 
  }

  .main-grid > a > button  {
    border: none;
    background: none;
    box-shadow: none;
  }
  
}

@media screen and (max-width: 328px) {
  .small-screen-hide {
    display: none;
  }
}

.option-loading {
  width: 58px;
  min-width: 58px;
  padding: 0px;
  margin-left: -8px;
}


@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-360deg);
  }
}


*:focus, input.form-control, [contenteditable="true"], span[role="option"], span[role="option"]:focus {
  outline:none !important;
  outline-width: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0px 9999px #100e17;
  box-shadow: inset 0 0 0px 9999px #100e17;
  background-color: transparent;
  color: #fff;
  background-clip: content-box;
}